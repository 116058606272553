<template>
	<div>
		<!-- 角色管理 -->
		<div class="queryBox mb20">
			<el-button size="mini" type="primary" icon="el-icon-plu" @click="openDrawer()">发布通知</el-button>
		</div>
		<template>
		
			<div>
				<el-table :max-height="height" :data="tableData" border stripe v-loading="loading" size="mini">
					<el-table-column width="150px" prop="title" label="标题" align="center" show-overflow-tooltip></el-table-column>
					<el-table-column width="250px" prop="content" label="内容" align="center" show-overflow-tooltip></el-table-column>
					<el-table-column label="商户" align="left">
						<template slot-scope="scope">
							  <el-button type="primary" size="mini" plain  v-if="scope.row.business_name">{{scope.row.business_name}}</el-button>
						</template>
					</el-table-column>
					<el-table-column label="通道" align="left">
						<template slot-scope="scope">
							<el-button type="success" size="mini" plain v-if="scope.row.payway_name">{{scope.row.payway_name}}</el-button>
						</template>
					</el-table-column>
					<el-table-column prop="operator" label="发送人" align="center"></el-table-column>
					<el-table-column prop="add_time" label="发送时间" align="center"></el-table-column>
					<el-table-column label="发送状态" align="left">
						<template slot-scope="scope">
							<el-tag size="mini" v-if="scope.row.state == 1" type="success">{{scope.row.notice_status_msg}}</el-tag>
							<el-tag size="mini" v-if="scope.row.state == 2" type="danger">{{scope.row.notice_status_msg}}</el-tag>
							<el-tag size="mini" v-if="scope.row.state == 3" type="warning">{{scope.row.notice_status_msg}}</el-tag>
						</template>
					</el-table-column>
					
					<el-table-column prop="" :label="$t('main.passagewayMenu.operation')" align="center">
						<template slot-scope="scope" class="flexX flexcenter">
							  <!-- <el-button class="mr10 mb10" size="mini" @click="resendMessage(scope.row.id)">重发</el-button> -->
							  <el-link type="primary" @click="resendMessage(scope.row.id)">重发</el-link>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</template>
		<div class="flexX  mt20 pageBox">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page" :page-sizes="pageSizesArr" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
		
		<el-drawer
		  title="发布通知"
		  :visible.sync="drawer"
		  :direction="direction"
		  :before-close="handleClose">
		  <div v-loading="dialogloading">
			<el-form class="formBox" status-icon :label-position="labelPosition">
			  <el-form-item label="标题">
				    <el-input v-model="title" autocomplete="off"></el-input>
			  </el-form-item>
			  <el-form-item label="内容">
			  		<el-input type="textarea" rows="6" v-model="content" autocomplete="off" show-word-limit></el-input>
			  </el-form-item>
			  
			  <el-form-item label="商户">
			  		<el-select v-model="traderSed" multiple placeholder="请选择">
			  		  <el-option
			  		    v-for="item in traders"
			  		    :key="item.id"
			  		    :label="item.business_name"
			  		    :value="item.id"
						:disabled="item.is_able==1?false:true">
			  		  </el-option>
			  		</el-select>
			  </el-form-item>
			  
			  <el-form-item label="通道">
			  		<el-select v-model="paywaySed" multiple placeholder="请选择">
			  		  <el-option
			  		    v-for="item in payways"
			  		    :key="item.id"
			  		    :label="item.name"
			  		    :value="item.id"
						:disabled="item.is_able==1?false:true">
			  		  </el-option>
			  		</el-select>
			  </el-form-item>
			    
			</el-form>
		  	<div class="textCenter">
		  		<el-button type="primary" @click="onSubmit">确认发送</el-button>
		  	</div>
		  </div>
		</el-drawer>
	</div>
</template>

<script>
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isIncludeHanzi
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				loading: false,
				dialogloading: false,
				date: [],
				input: '',
				tableData: [],
				dataTotal: {},
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
				item:{},
				height:0,
				// 抽屉.
				drawer: false,
				direction:'rtl',
				title: '',
				content: '',
				labelPosition: 'right',
				// 商户选择器
				traderSed: [],
				traders: [],
				// 通道选择器
				paywaySed: [],
				payways: [],
			}
		},
		created() {
			this.getNoticeList()
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 40 - 60
				console.log(this.height)
			})
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val =Math.floor(((value * 1)*100).toPrecision(12))
					return ((val/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			handleClose(done) {
				this.$confirm('确认关闭？')
				  .then(_ => {
					done();
					this.close();
				  })
				  .catch(_ => {});
			},
			// 打开抽屉
			openDrawer(){
				this.drawer = true;
				this.getTraders()
				this.getPayways()
			},
			// 消息列表
			getNoticeList(){
				this.$api.cahtNoticeList({
					page: this.page,
					size: this.pageSize
				}).then(res => {
					if (res.status === 1) {
						this.tableData = res.data.data	
						this.total = res.data.total
						
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false
				})
			},
			// 获取绑定商户列表
			getTraders(itemid){
				this.$api.getNoticeTraders({}).then(res => {
					if (res.status === 1) {
						this.traders = res.data		
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false
				})
			},
			// 获取绑定通道列表
			getPayways(itemid){
				this.$api.getNoticePayways({}).then(res => {
					if (res.status === 1) {
						this.payways = res.data		
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false
				})
			},
			
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.page = 1
				this.pageSize = val
				this.getNoticeList()
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.page = val
				this.getNoticeList()
			},
			close() {
				this.drawer = false;
				this.title = '';
				this.content = '';
				this.traderSed = []
				this.paywaySed = []
				this.traders=[]
				this.payways=[]
			},
			//新增
			onSubmit() {
				// 通知消息
				if (isEmpty(this.title, '请输入标题')) {
					return
				}
				// 通知消息
				if (isEmpty(this.content, '请输入内容')) {
					return
				}
				
				let data = {
					title: this.title,
					content: this.content,
					trader_list: JSON.stringify(this.traderSed),
					payway_list: JSON.stringify(this.paywaySed),
				}
				this.dialogloading = true
				this.$api.telegramSendMessage(data).then(res => {
					// console.log(res)
					this.dialogloading = false
					if (res.status === 1) {
						this.$message({
							type: 'success',
							message: res.msg
						});
						this.close();
						this.page = 1;
						this.getNoticeList()
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						});
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			
			//设置权限
			resendMessage(id){
				
				this.$confirm('确认重发？')
				  .then(_ => {
					  this.dialogloading = true
					  let data = {
						id: id,
					  }
					  this.$api.resend(data).then(res=>{
						if(res.status == 1){
							this.dialogloading = false
							this.getNoticeList()
							this.$message({type:"success",message:res.msg})
						} else{						
							this.$message({type:"error",message:res.msg})
						}
					  }).catch(error=>{
						this.dialogloading = false
					  })
					  
					  
				  })
				  .catch(_ => {});
				
				
			},
			
		},
		watch: {
			total1(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 40 - 60
					console.log(this.height)
				})
			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.formBox {
		width: 80%;
		margin: 0 auto;
	}
	.powerItem{
		display: inline-block;
		width: 33%;
		text-align: left;
		margin-bottom: 20px;
	}
	/* 滚动条宽度 */
	  ::-webkit-scrollbar {
	       width: 0px;
	   }
	   table tbody {
	       display: block;
	       overflow-y: auto;
	       -webkit-overflow-scrolling: touch;
			border-bottom: solid 1px #ddd;
	   }	 
	   table tbody tr:last-child td{
			border-bottom: none;
	   }	 
	   table thead tr, table tbody tr, table tfoot tr {
	       box-sizing: border-box;
	       table-layout: fixed;
	       display: table;
	       width: 100%;
	   }
	   table td{
		   word-break:break-all;
	   }
</style>
